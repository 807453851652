<template>
  <div>
    <!-- Expend Tasks -->
    <v-checkbox v-model="expand" :label="labelExpandTasks"></v-checkbox>

    <!-- tasks -->
    <v-expansion-panels v-model="panels" :key="panelKey" multiple>
      <template v-for="task in tasks">
        <v-expansion-panel :key="`task-${task.taskId}`">
          <v-expansion-panel-header>
            <task-banner :task="task"></task-banner>
          </v-expansion-panel-header>

          <!-- task content -->
          <v-expansion-panel-content>
            <project-task :project="project" :task="task"></project-task>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
// design
import {
  iconDotsHorizontal,
  iconTasks,
  iconExclamation
} from "@/design/icon/iconConst";

// model
import {
  assignmentMethod,
  findTaskPriority,
  priorityTextClass,
  taskPriority,
  userTaskLabel
} from "@/model/workflow/task/taskModel";
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";

export default {
  name: "ProjectTasks",
  components: {
    ProjectTask: () => import("@/components/project/ProjectTask"),
    TaskBanner: () => import("@/components/task/TaskBanner")
  },
  mixins: [reactiveMixin],
  data() {
    return {
      iconTasks: iconTasks,
      iconExclamation: iconExclamation,
      expand: false,
      panels: [],
      iconMore: iconDotsHorizontal,
      panelKey: 1
    };
  },
  props: {
    /**
     * Workflow Project
     * @type {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}}
     */
    project: undefined
  },
  computed: {
    /**
     * title
     * @return {string|string} title
     */
    title() {
      return this.project
        ? this.taskCount > 1
          ? `(${this.taskCount}) Tasks of ${this.projectName}`
          : `(${this.taskCount}) Task of ${this.projectName}`
        : "Tasks";
    },

    /**
     * tasks
     * @return {{taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}[]}
     */
    tasks() {
      return this.project?.userTasks ?? [];
    },

    /**
     * task Count
     * @return {number|number}
     */
    taskCount() {
      return this.tasks?.length ?? 0;
    },

    /**
     * project Name
     * @return {string} project Name
     */
    projectName() {
      return this.project?.projectName ?? "";
    },

    /**
     * label Expand Tasks
     * @return {string} formatted label Expend Tasks
     */
    labelExpandTasks() {
      return this.taskCount > 1
        ? `Expand ${this.taskCount} ${userTaskLabel}s`
        : `Expand ${this.taskCount} ${userTaskLabel}`;
    }
  },
  methods: {
    /**
     * set Panels
     * @param {Boolean|boolean} expand expand all panels
     */
    setPanels(expand) {
      if (expand) {
        const tasks = this.project?.userTasks ?? [];
        if (tasks.length === 0) {
          this.panels = [];
        } else {
          this.panels = tasks.map(el => {
            return tasks.indexOf(el);
          });
        }
      } else {
        this.panels = [];
      }
    },

    /**
     * display Priority
     * @param task
     * @return {string|string}
     */
    displayPriority(task) {
      return task
        ? `Priority ${findTaskPriority(task?.priority ?? -1)?.text ?? "?"}`
        : "";
    },

    /**
     * priority Text Class
     * @param task
     * @return {String|string|undefined}
     */
    priorityTextClass(task) {
      return priorityTextClass(task);
    },

    /**
     * display user task name with assignee
     * @param task
     * @return {string} formatted Task name with assignee
     */
    displayTaskName(task) {
      const taskName = task?.name ?? "";
      const actorId = task?.actorId ?? -1;
      const actorName = task?.actorName;

      // make sure whether a user has been assigned (manually/auto/self)
      if (actorId > 0) {
        // a user has been assigned (manually/auto/self)
        return `${taskName} - assigned to ${actorName}`;
      }

      // a user has not been assigned yet (manually/auto/self)
      const method = task?.assignmentMethodType ?? -1;
      if (
        method === assignmentMethod.auto ||
        method === assignmentMethod.self
      ) {
        return `${taskName} - ${this.assignedTo} assignment`;
      }

      return `${taskName} - assigned to ${this.assignedTo}`;
    },

    /**
     * visible Priority Icon
     * @param task
     * @return {boolean} true if visible Priority Icon
     */
    visiblePriorityIcon(task) {
      return (task?.priority ?? -1) === taskPriority.high;
    }
  },
  created() {
    /**
     * set expanding using local user settings here ...
     * @type {boolean}
     */
    this.expand = false;
  },
  watch: {
    expand(newValue) {
      // This force re-renders the component attached to this key, necessary to load chart.
      this.panelKey++;
      this.setPanels(newValue);
    },
    panels() {
      // This force re-renders the component attached to this key, necessary to load chart.
      this.panelKey++;
    }
  }
};
</script>
